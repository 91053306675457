<template>
  <AppModal v-model="model" class="paynearme-modal" :title="title">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #left>
          <AppBackButton analytics-name="send-fund-paynearme-back" @click="dismiss" />
        </template>
        <h3>{{ $t('PageSendMoneyFund.ButtonFundPayNearMe').value }}</h3>
      </AppModalHeader>
    </template>

    <XeFundTransfer :order-number="orderNumber" />
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import XeFundTransfer from '@galileo/components/XeFundTransfer/XeFundTransfer'

import { AppModal, AppModalHeader, AppBackButton, useVModel } from '@oen.web.vue2/ui'
export default {
  name: 'XePayNearMeModal',
  emits: ['input'],
  components: {
    AppModal,
    AppModalHeader,
    AppBackButton,
    XeFundTransfer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    orderNumber: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    return {
      $t,
      model,
    }
  },
}
</script>

<style scoped>
.paynearme-modal {
  ::v-deep .card {
    .card-content {
      @apply pt-0 pl-0 pr-0;

      @screen sm {
        @apply pl-12 pr-12;
      }

      .card-content-block {
        @apply h-full mb-0;

        @screen sm {
          @apply mb-12;
        }
      }
    }

    @screen sm {
      @apply max-w-xl;
    }

    @screen md {
      @apply max-w-2xl;
    }
  }
}
</style>

<template>
  <div>
    <AppCard sheet>
      <AppCardImageTitle
        :src="require('@galileo/assets/images/illustrations/payment-option.svg')"
        alt="Success"
        :title="$t('PageSendMoneyFund.Title').value"
      >
        <p>
          {{ $t('PageSendMoneyFund.Description').value }}
        </p>
      </AppCardImageTitle>

      <ButtonGroup>
        <AppButton analytics-name="send-fund-transfer" @click="fund">{{
          $t('PageSendMoneyFund.ButtonFundTransfer').value
        }}</AppButton>
        <AppButton theme="text-secondary" analytics-name="send-fund-later" @click="later">{{
          $t('PageSendMoneyFund.ButtonLater').value
        }}</AppButton>
      </ButtonGroup>
    </AppCard>
    <!-- PayNearMe modal -->
    <XePayNearMeModal v-model="showPayNearMe" :order-number="orderNumber" title="Pay near me" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import XePayNearMeModal from '@galileo/components/XePayNearMeModal/XePayNearMeModal'

import { AppButton, AppCard, AppCardImageTitle } from '@oen.web.vue2/ui'

import { useSendMoneyStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'SendMoneyFund',
  components: {
    ButtonGroup,
    AppCard,
    AppCardImageTitle,
    AppButton,
    XePayNearMeModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep
    const orderNumber = sendMoneyStore.form.createdOrderNumber

    const showPayNearMe = ref(false)
    const fund = () => {
      // TODO Staged transaction !
      showPayNearMe.value = true
    }
    const later = async () => {
      await router.goBackTo(
        {
          name: 'Activity',
        },
        true
      )
    }

    // Reset send money form if page reached
    sendMoneyStore.restoreDefaultForm()

    return {
      orderNumber,
      showPayNearMe,
      fund,
      later,
      activeStep,
      $t,
    }
  },
}
</script>

<style scoped></style>

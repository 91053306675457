<template>
  <div class="xe-fund-transfer">
    <AppSpinnerBig :loading="loading" :inline="true"></AppSpinnerBig>

    <iframe
      v-if="externalUrl"
      class="external-url"
      :class="{ 'external-url-loaded': !loading }"
      :src="externalUrl"
      @load="externalUrlLoaded"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'


import { AppSpinnerBig } from '@oen.web.vue2/ui'

import { useI18nStore, useActivityStore, useAppStore } from '@galileo/stores'

export default {
  name: 'XeFundTransfer',
  components: {
    AppSpinnerBig,
  },
  props: {
    orderNumber: {
      type: String,
      default: '',
      require: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const activityStore = useActivityStore()

    const loading = ref(true)
    const externalUrl = ref('')
    const appStore = useAppStore()

    const getTransactionDetails = async () => {
      try {
        const transaction = await activityStore.getTransactionDetails(props.orderNumber)
        if (transaction && transaction.externalPaymentUrl) {
          externalUrl.value = transaction.externalPaymentUrl
        }
      } catch (ex) {
        appStore.logException('Exception during getting transfer details for payment', ex)
        appStore.messageBoxGenericError()
      }
    }
    getTransactionDetails()

    const externalUrlLoaded = () => {
      loading.value = false
    }

    return {
      externalUrl,
      loading,
      externalUrlLoaded,
      $t,
    }
  },
}
</script>
<style scoped>
.xe-fund-transfer {
  @apply w-full h-full;
  @apply flex flex-col;
  @apply justify-center align-middle;
  min-height: 60vh; /* No tailwind equivalent */
}
.external-url {
  @apply hidden;
  @apply flex-grow;
}
.external-url-loaded {
  @apply block;
}
</style>
